import React from 'react';

import {
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import { ChallengeDetails } from '../../../../../../../../components-shared/ChallengeDetails';
import { useChallengeData } from '../../../../../../../../contexts/ChallengeDataProvider/ChallengeDataContext';
import challengeSettings from '../../../../../../settingsParams';
import { useInviteLink } from '../../../../../../../../contexts/InviteLink/InviteLinkContext';
import { TextAlignment } from '../../../../../../Settings/challengeSettings/challengeSettings.types';

import { st, classes } from './VisitorPageProgramDetails.st.css';
import TextWithDirection from '../../../../../../../../components-shared/TextWithDirection';
import { useCSSPBStyle } from '../../../../../../../../hooks/useCSSPBStyle';
import { FCWithChildren } from '@wix/challenges-web-library';

interface IVisitorPageProgramDetailsProps {
  className?: string;
  alignment?: TextAlignment;
}

export const VisitorPageProgramDetails: FCWithChildren<
  IVisitorPageProgramDetailsProps
> = ({ className = null, alignment = TextAlignment.Left }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const {
    challengeData: { challenge },
  } = useChallengeData();
  const settings = useSettings();
  const { goToInviteLink } = useInviteLink();
  const { experiments } = useExperiments();
  const cssPBEnabled = experiments.enabled('specs.programs.OOIStyleBP');
  const showOneApp =
    cssPBEnabled || settings.get(challengeSettings.displayOneApp);
  const getCSSPBStyle = useCSSPBStyle();
  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          alignment,
        },
        className,
      )}
    >
      {challenge.settings.description.details ? (
        <div className={classes.contentDescription}>
          <ChallengeDetails
            isMobile={isMobile}
            details={challenge.settings.description.details}
          />
        </div>
      ) : null}

      {showOneApp ? (
        <TextWithDirection>
          <p
            className={classes.contentDescription}
            style={getCSSPBStyle({ displayVar: '--opgDisplayOneApp' })}
          >
            {t('challenge.description.requires-one-app')}{' '}
            <button
              name={t('challenge.description.mobile-app')}
              onClick={() => {
                goToInviteLink();
              }}
              className={classes.link}
            >
              {t('challenge.description.mobile-app')}
            </button>
          </p>
        </TextWithDirection>
      ) : null}
    </div>
  );
};
