import React from 'react';

import { VisitorPageContent } from '../VisitorPageContent';
import { VisitorPageSidebar } from '../VisitorPageSidebar';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';
import {
  MobileSidebarPlacement,
  SidebarPlacement,
} from '../../../../../Settings/challengeSettings/challengeSettings.types';

import { st, classes } from './VisitorPageMain.st.css';
import { useIsFullWithInViewer } from '../../../../../../../services/hooks/useIsFullWidthInViewer';
import { FCWithChildren } from '@wix/challenges-web-library';

interface IVisitorPageMainProps {
  className?: string;
  dataHook?: string;
}

export const VisitorPageMain: FCWithChildren<IVisitorPageMainProps> = ({
  className,
  dataHook,
}) => {
  const { isMobile } = useEnvironment();
  const isStretched = useIsFullWithInViewer();
  const settings = useSettings();

  const isSidebarEnabled = settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const isSidebarReversed =
    isSidebarEnabled &&
    ((!isMobile &&
      settings.get(challengeSettings.visitorPageBodySidebarPlacement) ===
        SidebarPlacement.left) ||
      (isMobile &&
        settings.get(
          challengeSettings.visitorPageBodyMobileSidebarPlacement,
        ) === MobileSidebarPlacement.top));

  return (
    <div
      className={st(
        classes.root,
        {
          mobile: isMobile,
          stretched: isStretched && !isMobile,
          // eslint-disable-next-line no-nested-ternary
          sidebarType: isSidebarEnabled
            ? !isSidebarReversed
              ? 'classic'
              : 'reversed'
            : 'hidden',
        },
        className,
      )}
      data-hook={dataHook}
    >
      <VisitorPageContent
        className={classes.content}
        sectionClassName={classes.pageSection}
        boxElementClassName={classes.boxElement}
        startDateLabelClassName={classes.startDateSelectionDescription}
      />
      <VisitorPageSidebar
        className={classes.sidebar}
        sectionClassName={classes.pageSection}
        boxElementClassName={classes.boxElement}
        startDateLabelClassName={classes.startDateSelectionDescription}
      />
    </div>
  );
};
