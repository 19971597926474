import React from 'react';
import { Spinner } from '../../../../../components-shared/Spinner';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';

import { classes, st } from './SidebarSectionView.st.css';
import { MediaCover } from '../../../../../components-shared/MediaCover';

import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { StepViewHidden } from '../StepViewHidden';
import { Challenges } from '../../../../../editor/types/Experiments';
import { isSectionLocked } from '../../../../../selectors/sections';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';
import { StepViewBreadcrumbs } from '../StepViewBreadcrumbs';
import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ISidebarSectionView {
  currentSectionId: string;
  goToCurrentStep(): void;
  pageRef: React.RefObject<HTMLElement>;
  goToMobileList(): void;
}

export const SidebarSectionView: FCWithChildren<ISidebarSectionView> = (
  props,
) => {
  const {
    listParticipantSections,
    isListParticipantSectionsRequestInProgress,
  } = useSections();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const section = listParticipantSections.find(
    (s) => s.id === props.currentSectionId,
  );

  useSingleEndInteraction(
    FedopsInteraction.ViewSectionAtSidebarLayout,
    section?.id,
  );

  if (!section) {
    return null;
  }

  const isHideSection =
    experiments.enabled(Challenges.enableDripContent) &&
    isSectionLocked(section);

  if (isHideSection) {
    return (
      <StepViewHidden
        isWeekFixForCTA={false}
        date={section.transitions[0].waitingDate.startDate}
        titleKey="live.section.section-locked.title"
        goToCurrentStep={props.goToCurrentStep}
        descriptionKey="live.section.section-locked.description"
      />
    );
  }

  return isListParticipantSectionsRequestInProgress ? (
    <Spinner />
  ) : (
    <div
      id={props.currentSectionId}
      className={st(classes.root, {
        mobile: isMobile,
      })}
    >
      {isMobile && (
        <StepViewBreadcrumbs goToMobileList={props.goToMobileList} />
      )}
      <Fullscreen pageRef={props.pageRef} />

      <TextWithDirection>
        <h3 className={classes.stepViewTitle}>
          {section.source.settings.description.title}
        </h3>
      </TextWithDirection>

      <MediaCover
        fit="contain"
        media={section.source.settings.description.media}
        className={classes.sectionMedia}
      />

      <div className={classes.sectionDescription}>
        <Ricos
          key={section?.id}
          details={section?.source?.settings?.description?.details}
          context="section_description"
          contentId={section?.id}
        />
      </div>
    </div>
  );
};
