import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { StepViewHidden } from '../StepViewHidden';

import {
  ListParticipantStepsResponse,
  ParticipantSection,
  ParticipantStep,
} from '@wix/ambassador-challenges-v1-participant/types';
import { Challenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { Spinner } from '../../../../../components-shared/Spinner';
import { Fullscreen } from '../Fullscreen/Fullscreen';
import utils, { getFlatStepsList } from '../../views/utils';

import { classes, st } from './SideBarStepView.st.css';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useSingleEndInteraction } from '../../../../../contexts/Monitoring/MonitoringContext';
import TextWithDirection from '../../../../../components-shared/TextWithDirection';

import { Ricos } from '../../../../../components-shared/Ricos/loadable/Ricos';
import { FCWithChildren } from '@wix/challenges-web-library';

export interface ISideBarStepViewProps {
  dataHook?: string;
  challenge: Challenge;
  isListParticipantSectionsRequestInProgress: boolean;
  isParticipantStepsLoading: boolean;
  currentStep: ParticipantStep;
  listParticipantSections: ParticipantSection[];
  participantSteps: ListParticipantStepsResponse;
  goToCurrentStep(e: any): void;
  Breadcrumbs: () => React.ReactElement;
  StepShareButton: () => React.ReactElement;
  pageRef: React.RefObject<HTMLElement>;
}

export const SideBarStepView: FCWithChildren<ISideBarStepViewProps> = (
  props,
) => {
  const { isMobile } = useEnvironment();
  const {
    challenge,
    isListParticipantSectionsRequestInProgress,
    isParticipantStepsLoading,
    currentStep,
    listParticipantSections,
    participantSteps,
    Breadcrumbs,
    StepShareButton,
    pageRef,
  } = props;

  useSingleEndInteraction(
    FedopsInteraction.ViewStepAtSidebarLayout,
    currentStep?.id,
  );

  if (!currentStep) {
    return <></>;
  }

  return isListParticipantSectionsRequestInProgress ||
    isParticipantStepsLoading ? (
    <Spinner />
  ) : (
    <div
      id={currentStep.id}
      className={st(classes.root, {
        mobile: isMobile,
      })}
      data-hook={props.dataHook || null}
    >
      {!utils.isStepHidden(
        challenge,
        getFlatStepsList({
          listParticipantSections,
          participantSteps,
        }),
        currentStep,
      ) ? (
        <>
          <Breadcrumbs />
          <Fullscreen pageRef={pageRef} />

          <TextWithDirection>
            <h3 className={classes.stepViewTitle}>
              {currentStep?.source?.settings?.general?.description?.title}
              {isMobile ? (
                <div className={classes.stepViewTitleShare}>
                  <StepShareButton />
                </div>
              ) : null}
            </h3>
          </TextWithDirection>

          <Ricos
            key={currentStep?.id}
            details={
              currentStep?.source?.settings?.general?.description?.details
            }
            contentId={currentStep?.id}
            context="program_step"
          />
        </>
      ) : (
        <StepViewHidden
          date={currentStep?.dateFrame?.start}
          goToCurrentStep={(e) => props.goToCurrentStep(e)}
        />
      )}
    </div>
  );
};
