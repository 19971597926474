import React from 'react';

import { VisitorPageSectionsMapper } from '../sections';

import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../../../settingsParams';
import { useSidebarItems } from '../../../../../Settings/tabs/Sections/components/VisitorV2SectionsTabContent/constants/splitItemsHelper';

import { st, classes } from './VisitorPageSidebar.st.css';
import { FCWithChildren } from '@wix/challenges-web-library';

interface IVisitorPageSidebarProps {
  className?: string;
  sectionClassName?: string;
  boxElementClassName?: string;
  startDateLabelClassName?: string;
}

export const VisitorPageSidebar: FCWithChildren<IVisitorPageSidebarProps> = ({
  className = null,
  sectionClassName = null,
  boxElementClassName = null,
  startDateLabelClassName = null,
}) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const isSidebarEnabled = !!settings.get(
    challengeSettings.visitorPageBodyEnableSidebar,
  );
  const sidebarItems = useSidebarItems();
  const sidebarItemsIds = sidebarItems.map((item) => item.id);
  const alignment = isMobile
    ? settings.get(challengeSettings.contentTextAlignment)
    : settings.get(challengeSettings.visitorPageBodySidebarTextAlignment);
  return (
    <aside
      className={st(
        classes.root,
        {
          mobile: isMobile,
          // for mobile we get the alignment from the base content
          sidebarTextAlignment: alignment,
        },
        className,
      )}
    >
      {isSidebarEnabled ? (
        <>
          <VisitorPageSectionsMapper
            sections={sidebarItemsIds}
            sectionClassName={sectionClassName}
            boxElementClassName={boxElementClassName}
            startDateLabelClassName={startDateLabelClassName}
            alignment={alignment}
          />
        </>
      ) : null}
    </aside>
  );
};
